import { __decorate, __metadata } from "tslib";
import { action, observable } from 'mobx';
var PermissionVm = (function () {
    function PermissionVm() {
        this.isEnableMC = false;
        this.isCorpMC = false;
    }
    PermissionVm.prototype.setEnableMC = function (value) {
        this.isCorpMC = (value === null || value === void 0 ? void 0 : value.type) === 'MC';
        if (!this.isCorpMC) {
            this.isEnableMC = true;
            return;
        }
        var permissions = value === null || value === void 0 ? void 0 : value.permissions;
        if (permissions && permissions[0] && permissions[0].auth) {
            this.isEnableMC = true;
        }
        else {
            this.isEnableMC = false;
        }
    };
    PermissionVm.prototype.getEnabledEdit = function (value) {
        if (!this.isCorpMC) {
            return true;
        }
        var nodePermissions = value === null || value === void 0 ? void 0 : value.permissions;
        if (Array.isArray(nodePermissions)) {
            return !!nodePermissions.find(function (el) { return el.name === 'ALL' && el.auth; });
        }
        return false;
    };
    __decorate([
        observable,
        __metadata("design:type", Boolean)
    ], PermissionVm.prototype, "isEnableMC", void 0);
    __decorate([
        observable,
        __metadata("design:type", Boolean)
    ], PermissionVm.prototype, "isCorpMC", void 0);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], PermissionVm.prototype, "setEnableMC", null);
    return PermissionVm;
}());
export { PermissionVm };
