/**
 * Created by XuTian on 2017/7/12.
 */

import key from './key'
import { showMessage } from '@ekuaibao/show-util'
import { Resource } from '@ekuaibao/fetch'

const payeeAction = new Resource('/api/pay/v2/accounts')
const payeeActionV2 = new Resource('/api/pay/v2/payeeinfoconfigs')
const banks = new Resource('/api/pay/v1/banks/branchesByKey')
import { app as api } from '@ekuaibao/whispered'

export function getPayeeList(params) {
  let data = {
    ...params,
    // join: 'logs.operatorId,operator,/v1/organization/staffs',
    // join$1: 'staffId,staffId,/v1/organization/staffs'
  }
  return {
    type: key.GET_PAYEE_LIST,
    payload: payeeAction.GET('', data)
  }
}

export function savePayee(data) {
  data.active && delete data.active
  data.staffId && delete data.staffId
  let promise
  if (data['id']) {
    promise = api.invoke('@vendor:dd:message:code').then(messageCode => payeeAction.PUT('/$id', data, { messageCode }))
  } else {
    promise = payeeAction.POST('', data)
  }

  return promise.catch(e => {
    showMessage.error(e.msg)
    return Promise.reject(e)
  })
}

export function setActivePayee(data) {
  let { value, id } = data
  return {
    type: key.SET_ACTIVE_PAYEE,
    payload: value ? payeeAction.PUT('/$id/disable', { id }) : payeeAction.PUT('/$id/restore', { id })
  }
}

export function setShare(data) {
  return {
    type: key.SET_SHARE,
    payload: api
      .invoke('@vendor:dd:message:code')
      .then(messageCode => {
        return payeeAction.PUT('/$id/visibility', { ...data }, { messageCode })
      })
      .catch(e => Promise.reject(e))
  }
}

export function setAllowShare(data) {
  return {
    type: key.SET_ALLOW_SHARE,
    payload: payeeAction.PUT('/config/sharing', data)
  }
}

export function importExcel(data) {
  return {
    type: key.IMPORT_EXCEL,
    payload: payeeAction.POST('/import/excel', data)
  }
}

// 设置是否可以创建账户
export function setAllowCreate(allow) {
  return {
    type: key.IS_ALLOW_CREATE_ACCOUNT,
    payload: payeeAction.PUT('/config/create', allow)
  }
}

//收款信息敏感化
export function setConfigAttr(data) {
  return {
    type: key.SET_CONFIG_ATTR,
    payload: payeeActionV2.PUT('', data)
  }
}

export function setDefaultPayee(data) {
  let request = payeeAction.PUT('/$id/default', { id: data.id })
  Promise.resolve(request).then(() => api.invokeService('@common:get:default:payee'))
  return {
    type: key.SAVE_PAYEE,
    payload: request
  }
}

export function getBanks(data = { searchKey: '' }) {
  data.start = 0
  data.count = 100
  return {
    type: key.GET_BANKS,
    payload: banks.GET('', { ...data })
  }
}

export function getPayeeAccountList(params) {
  let data = {
    ...params,
  }
  return payeeAction.GET('', data)
}

// 批量配置收款账户可见性
export function putPayeeAccountVisibility(params) {
  return payeeAction.PUT('/visibility', {...params})
}
