/**
 * Created by XuTian on 2017/7/12.
 */

export const ID = '@payeeAccount'

export default {
  ID: ID,
  GET_PAYEE_LIST: `${ID}/GET_PAYEE_LIST`,
  SAVE_PAYEE: `${ID}/SAVE_PAYEE`,
  SET_ACTIVE_PAYEE: `${ID}/SET_ACTIVE_PAYEE`,

  SET_DEFAULT: `${ID}/SET_DEFAULT`,
  SET_SHARE: `${ID}/SET_SHARE`,
  SET_ALLOW_SHARE: `${ID}/SET_ALLOW_SHARE`,

  IMPORT_EXCEL: `${ID}/IMPORT_EXCEL`,
  IS_ALLOW_CREATE_ACCOUNT: `${ID}/IS_ALLOW_CREATE_ACCOUNT`,
  SET_ACCOUNT_VISIBILITY: `${ID}/SET_ACCOUNT_VISIBILITY`,
  SET_CONFIG_ATTR: `${ID}SET_CONFIG_ATTR`,

  GET_BANKS: `${ID}/GET_BANKS`
}
