/**
 * Created by XuTian on 2017/7/12.
 */

// @i18n-ignore-all
import { app as api } from '@ekuaibao/whispered';
import { getBanks } from './payee-account-action';
import { getPayeeInfoByIds } from './fetchUtil';

export const Universal_Unique_Key = 'customPayeeAccount.pc'

export default [
  {
    id: '@payeeAccount',
    reducer: () => import('./payee-account-reducer'),
    path: '/payeeAccount',
    ref: '/',
    onload: () => import('./payee-account-view'),
    'get:banks': (params) => {
      return api.dispatch(getBanks(params));
    },
    'get:payeeInfo:by:ids': (params) => getPayeeInfoByIds(params),
  },
  {
    point: '@@layers',
    prefix: '@payeeAccount',
    onload: () => import('./layers'),
  },
  {
    point: '@@menus',
    onload: () => [
      {
        id: 'payeeAccount',
        pId: 'enterprise-manage',
        permissions: ['BANK_ACCOUNT_MANAGE', 'SYS_ADMIN','RECEIPT_ACCOUNT'],
        weight: 8,
        label: '收款账户',
        href: '/payeeAccount',
        icon: 'payee-account'
      }
    ]
  },
  {
    resource: '@payeeAccount',
    value: {
      ['vms/Permission.vm']: require('./vms/Permission.vm')
    }
  }
]
